<template >
    <div>
        <div class="app-modal__box">
            <div class="app-modal__in">
                <div
                    class="app-modal__header d-flex f-between p-5 m-title-modal"
                >
                    <p class="large--title m-0">{{ $t('message.custom_form') }}</p>
                    <div>
                        <crm-store-update-close
                            :permission="$options.name"
                            :button_type="'store'"
                            :loading="loadingButton"
                            @c-submit="submit(true)"
                            @c-close="close()"
                        ></crm-store-update-close>
                    </div>
                </div>
            </div>
            <!-- app-modal__header end -->

				<el-row>
					<el-col :span="12">
						<div class="app-modal__body p-5 pb-0">
							<div class="timeline-items__right rounded-sm w-100 p-4">
								<el-form ref="form" :model="form" :rules="rules" label-position="top">
									<el-form-item :label="$t('message.projects')" prop="project_id"> 
										<select-projects v-model="form.project_id" :id="form.project_id"></select-projects>
									</el-form-item>
									<el-form-item :label="$t('message.header')" class="label_mini" prop="header">
										<ckeditor :editor="editor" v-model="form.header" :config="editorConfig"></ckeditor>
									</el-form-item>
									<el-form-item :label="$t('message.content')" class="label_mini">
										<ckeditor :editor="editor" v-model="form.content" :config="editorConfig"></ckeditor>
									</el-form-item>
									<el-form-item :label="$t('message.footer')" class="label_mini">
										<ckeditor :editor="editor" v-model="form.footer" :config="editorConfig"></ckeditor>
									</el-form-item>
								</el-form>
								<div>
									<el-button class="mr-3" @click="dialogFormVisible = true" type="primary">{{ $t('message.add_field') }}</el-button>
									<el-switch class="mr-3" v-model="form.type_form" @change="changeFormType" :active-text="$t('message.dealForm')" :inactive-text="$t('message.simpleForm')"></el-switch>
									<el-button class="mr-3" v-show="form.type_form" @click="addProductsSelect" type="primary">{{ $t('message.add') + ' ' + $t('message.products') }}</el-button>
								</div>
							</div>
							<div>
								<table class="table-my-code table-bordered custom-form-table">
									<thead class="thead-dark">
										<tr>
											<th scope="col">#</th>
											<th scope="col">{{$t('message.name')}}</th>
											<th scope="col">{{ $t('message.Required') }}</th>
											<th scope="col">{{ $t('message.Type') }}</th>
											<th scope="col">{{$t('message.delete')}}</th>
										</tr>
									</thead>
									<draggable v-model="data_form"  tag="tbody"  :sort="true"   ghost-class="ghost"  class="sortable-ghost"  @change="changeDrag">
										<tr v-for="(item, index) in data_form" :key="index">
											<td scope="row" style="width:50px;">{{ ++index }}</td>
											<td>{{ item.label }}</td>
											<td>{{ item.required ? $t('message.required') : $t('message.not_required') }}</td>
											<td style="width:150px;">{{ item.type }}</td>
											<td style="width:70px;">
												<div class="custom-form-table-settings">
													<el-button v-if="item.deal_form && !item.not_visible" type="primary" @click="updateProducts(item)" icon="el-icon-edit" circle size="mini"></el-button>
													<el-button v-if="!item.not_visible" type="danger" @click="removeRow(item, true)"  icon="el-icon-delete delete_icon" circle size="mini"></el-button>
												</div>
											</td>
										</tr>
									</draggable>
								</table>
							</div>
						</div>
					</el-col>
						<!-- end-col -->
					<el-col :span="12">
						<div class="app-modal__body p-5 pb-0">
							<div class="timeline-items__right rounded-sm w-100 p-4 text-center">
								<h2 v-html="form.header"></h2>
								<p v-html="form.content"></p>
								<!-- FORM BODY :label-width="formLabelWidth" -->
									<el-form ref="custom_form" :model="custom_form" :rules="custom_rules" >
										<div v-for="(item, index) in data_form" :key="index" class="w-100">
											<el-form-item :label="item.label" :prop="item.name">
												<div v-if="['el-radio', 'el-checkbox'].includes(item.type)" class="w-100">
													<el-checkbox-group v-model="custom_form[item.name]" 	@change="changeComponent" v-if="item.props.is_multiple_choice">
														<el-row>
															<el-col 
																:span="6" 
																v-for="checkbox in item.options" 
																:key="'checkbox-'+checkbox.label" 
																style="margin-right: 10px;"
															>
																<component
																	:is="item.type" 
																	v-bind="{...item.props, label: checkbox.label}"
																	class="w-25"
																>
																	{{ checkbox.label }}
																</component>
															</el-col>
														</el-row>
													</el-checkbox-group>
													<el-radio-group v-model="custom_form[item.name]" v-else>
														<el-row>
															<el-col 
																:span="6" 
																v-for="radio in item.options" 
																:key="'radio-'+radio.label" 
																style="margin-right: 10px;"
															>
																<component
																	:is="item.type" 
																	v-bind="{...item.props, label: radio.label}"
																	class="w-25 forma-cilent-info-radio"
																	>
																	{{ radio.label }}
																</component>
															</el-col>
														</el-row>
													</el-radio-group>
												</div>
												<div v-else-if="item.type == 'el-upload'">
													<el-upload
														class="upload-demo w-50"
														action="#"
														:file-list="custom_form[item.name]"
														list-type="picture"
														:auto-upload="false"
														multiple
														:limit="5"
														>
														<el-button size="small" type="primary">{{ $t('message.UploadFile') }}</el-button>
														<div slot="tip" class="el-upload__tip">{{ $t('message.UploadFileText') }}</div>
													</el-upload>
												</div>
												<div v-else>
													<component 
														:is="item.type" 
														v-model="custom_form[`${item.name}`]" 
														v-bind="{...item.props}" 
														:placeholder="item.label" 
														clearable
														class="w-100"
														v-mask="getMaskFormat(item.props)"
													>
														<div v-if="item.type == 'el-select'">
															<el-option v-for="(option, o_index) in item.options" :key="o_index" :label="option.label" :value="option.value"></el-option>
														</div>
													</component>
												</div>
											</el-form-item>
										</div>
									</el-form>
						
								<p v-html="form.footer"></p>
								<!-- <el-button @click="submit()">send</el-button> -->
							</div>
						</div>
					</el-col>
				</el-row>
            <!-- end app-modal__body -->
        </div>
		<!-- CUSTOM FORM -->
		<el-dialog   
			:class="mode ? 'l-modal-style' : 'd-modal-style'" 
		 	ref="dialog" title="" :visible.sync="dialogFormVisible" append-to-body 
			@closed="clearDialogForm()" :close-on-click-modal="false"
		>
			<el-form ref="dialog_form" :model="dialog_form" label-width="120px" label-position="top">
				<el-form-item :label="$t('message.label')">
					<el-input v-model="dialog_form.label" clearable></el-input>
				</el-form-item>
				<el-row>
					<el-col :span="8">
						<el-form-item :label="$t('message.Rule')">
							<el-checkbox v-model="dialog_form.required" border></el-checkbox>
						</el-form-item>
					</el-col>
				</el-row>
				<el-form-item :label="$t('message.Type')">
					<el-select v-model="dialog_form.type"  @change="changeType" :placeholder="$t('message.Type')" clearable>
						<el-option :label="$t('message.Input')" value="el-input"></el-option>
						<el-option :label="$t('message.InputNumber')" value="el-input-number"></el-option>
						<el-option :label="$t('message.Select')" value="el-select"></el-option>
						<el-option :label="$t('message.Radio')" value="el-radio"></el-option>
						<el-option :label="$t('message.Checkbox')" value="el-checkbox"></el-option>
						<el-option :label="$t('message.Switch')" value="el-switch"></el-option>
						<el-option :label="$t('message.DatePicker')" value="el-date-picker"></el-option>
						<el-option :label="$t('message.Upload')" value="el-upload"></el-option>
						<!-- <el-option label="DateTimePicker" value="el-date-time-picker"></el-option> -->
					</el-select>
				</el-form-item>
				<el-row :gutter="20" v-if="['el-select', 'el-radio', 'el-checkbox'].includes(dialog_form.type)">
					<el-col :span="24">
						<el-table :data="options" border>
							<el-table-column :label="$t('message.label')">
								<template slot-scope="item">
									<el-input v-model="item.row.label" size="mini"></el-input>
								</template>
							</el-table-column>
							<el-table-column>
								<template slot-scope="item">
									<el-button type="danger" @click="removeRow(item.row, false)" icon="el-icon-delete delete_icon" circle size="mini"></el-button>
								</template>
							</el-table-column>	
						</el-table>
						<el-button type="success" @click="addNewOption(dialog_form.type)">{{ $t('message.addNewOption') }}</el-button>
					</el-col>
				</el-row>
				<el-row :gutter="20" v-if="dialog_form.type == 'el-switch'">
					<el-col :span="12">
						<el-input :placeholder="$t('message.is_active') + ' ' + $t('message.text_t')" v-model="dialog_form.active_text" clearable></el-input>
					</el-col>
					<el-col :span="12">
						<el-input :placeholder="$t('message.inactive') + ' ' + $t('message.text_t')" v-model="dialog_form.inactive_text" clearable></el-input>
					</el-col>
				</el-row>
			</el-form>
				<span slot="footer" class="dialog-footer">
					<el-button @click="dialogFormVisible = false">{{ $t('message.exit') }}</el-button>
					<el-button type="primary" @click="generateDynamicComponent(dialog_form)">{{ $t('message.create_component') }}</el-button>
				</span>
		</el-dialog>
		<!-- CUSTOM FORM -->

		<!-- ADD PRODUCTS -->
		<el-dialog
			:class="mode ? 'l-modal-style' : 'd-modal-style'"
			:title="$t('message.add') + ' ' + $t('message.products')" 
			:visible.sync="dialogProductVisible" 
			append-to-body width="70%" 
			@closed="clearDialogProducts()"
			>
			<el-row :gutter="20" style="margin-bottom: 20px;">
				<el-col :span="12">
					<multi-select-filials v-model="filial_ids" :filial_ids="filial_ids" :collapse_tags="false" @c-change="changeFilials"></multi-select-filials>
				</el-col>
				<el-col :span="12">
					<multi-select-categories v-model="category_ids" :category_ids="category_ids" :collapse_tags="false"></multi-select-categories>
				</el-col>
			</el-row>
			<el-row :gutter="20">
				<el-col :span="24">
					<div class="custom-form-transfer"> 
						<el-transfer
							:class="'el__transfer__custom__class'"
							v-model="product_ids"
							:props="{
								key: 'value',
								label: 'label'
							}"
							:data="product_list"
							>
						</el-transfer>
					</div>
				</el-col>
			</el-row>

			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="addProducts(product_ids, form_index)">
					{{ edit_product ? $t('message.edit_m', {m : $t('message.products')}) : $t('message.add') }}
				</el-button>
			</span>
		</el-dialog>
		<!-- ADD PRODUCTS -->
   </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import custom_form from '@/utils/mixins/models/custom_form';
export default {
	mixins: [form, drawer, custom_form],
   data() {
		return {};
   },
	methods: {
		...mapActions({
			save: "customForm/store",
		}),
		afterOpen(){
			this.form = JSON.parse(JSON.stringify(this.model));	
		},
	},
};
</script>
<style>
.custom-form-table-settings{
	display: flex;
	justify-content: flex-start;
	align-items: center;
}
.delete_icon{
	color: white;
	font-weight: normal;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.custom-form-table tr > td{
	vertical-align: middle !important;
}
.sortable-ghost, .sortable-chosen {
   cursor: grabbing;
}
.custom-form-transfer{
	display: flex;
	justify-content: center;
	align-items: center;
}
.custom-form-transfer .el__transfer__custom__class{
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
}

.custom-form-transfer .el-transfer-panel{
	width: 45%;
}
.custom-form-transfer .el-transfer__buttons{
	display: inline-flex !important;
}
.custom-form-transfer .el-transfer__button{
	margin-bottom: 10px !important;
}
.custom-form-transfer .transfer-footer{
	margin-left: 20px;
	padding: 0px 5px;
}

/* .my___pagination{
	margin-top: 0 !important;
} */

@media only screen and (max-width: 900px) {
	.custom_class{
		display: flex;
		flex-wrap: wrap;
		width: 100%;
	}
   .el-transfer-panel{
		width: 100%;
		margin-bottom: 20px;
	}
}
</style>