<template>
    <div class="">
        <div class="card-table" :class="mode ? 'card__tableday' : 'card__tablenight'">
            <div class="card-table-body ifon-add-title-block">
                <el-row :gutter="20">
                    <el-col :span="12">
                        <div class="caz-blocks-sarcho-title">
                            <div :class="mode ? 'content__titleday' : 'content__titlenight'" class="content-title d-flex align-center mr-2">
                                {{ $t("message.custom_form") }}
                            </div>
                            <div class="block-sarche">
                                <div class="header__search">
                                    <crm-input
                                        :size="'small'"
                                        :class="mode ? 'input__day' : 'input__night'"
                                        :className="'w100'"
                                        v-model="filterForm.search"
                                        :icon="'el-icon-search'"
                                    ></crm-input>
                                </div>
                            </div>
                        </div>
                    </el-col>

                    <el-col :span="12" class="flex-style text-right">
                        <crm-create-and-column-settings
                            :permission="$options.name"
                            :class="mode ? 'button__settingsday' : 'button__settingsnight'"
                            @c-create="drawerCreate = true"
                            :columns="columns"
                            @c-change="updateColumn"
                        >
                        </crm-create-and-column-settings>
                    </el-col>
                </el-row>
            </div>
            <!-- end ifon-add-title-block -->

            <div class="card-table-header table-crm-smart">
                <table
                    class="table-my-code table-bordered"
                    :class="mode ? 'table__myday' : 'table__mynight'"
                    v-loading="loadingData"
                >
                    <thead>
                        <tr>
                            <th class="w50p" v-if="columns.id.show">
                                {{ columns.id.title }}
                            </th>

                            <th v-if="columns.user.show">
                                {{ columns.user.title }}
                            </th>

							<th v-if="columns.project.show">
                                {{ columns.project.title }}
                            </th>

							<!-- <th v-if="columns.header.show">
                                {{ columns.header.title }}
                            </th> -->
							<th v-if="columns.link.show">
                                {{ columns.link.title }}
                            </th>
                            <th v-if="columns.script.show">
                                {{ columns.script.title }}
                            </th>
							<th v-if="columns.deal.show">
                                {{ columns.deal.title }}
                            </th>
							<th v-if="columns.view.show">
                                {{ columns.view.title }}
                            </th>
                            <th v-if="columns.created_at.show">
                                {{ columns.created_at.title }}
                            </th>

                            <th v-if="columns.updated_at.show">
                                {{ columns.updated_at.title }}
                            </th>

                            <th v-if="columns.settings.show">
                                {{ columns.settings.title }}
                            </th>
                        </tr>

                        <tr class="filter_sorche">
                            <th v-if="columns.id.show">
                                <el-input
                                    clearable
                                    size="mini"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.id"
                                    :placeholder="columns.id.title"
                                    class="id_input"
                                ></el-input>
                            </th>

                            <th v-if="columns.user.show">
                                <crm-input
                                    :placeholder="columns.user.title"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.user_id"
                                ></crm-input>
                            </th>
									 
                            <th v-if="columns.project.show">
                                <crm-input
                                    :placeholder="columns.project.title"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.project"
                                ></crm-input>
                            </th>
									 
                            <!-- <th v-if="columns.header.show">
                                <crm-input
                                    :placeholder="columns.header.title"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.header"
                                ></crm-input>
                            </th> -->
							<th v-if="columns.link.show">
                                <crm-input
                                    :placeholder="columns.link.title"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.link"
                                ></crm-input>
                            </th>
                            <th v-if="columns.script.show">
                            </th>
							<th v-if="columns.deal.show">
                                <crm-input
                                    :placeholder="columns.deal.title"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.deal"
                                ></crm-input>
                            </th>
							<th v-if="columns.view.show">
                                <!-- <crm-input
                                    :placeholder="columns.view.title"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.view"
												:disabled="true"
                                ></crm-input> -->
                            </th>
                            <th v-if="columns.created_at.show">
                                <crm-date-picker
                                    :placeholder="columns.created_at.title"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.created_at"
                                ></crm-date-picker>
                            </th>

                            <th v-if="columns.updated_at.show">
                                <crm-date-picker
                                    :placeholder="columns.updated_at.title"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.updated_at"
                                ></crm-date-picker>
                            </th>

                            <th
                                class="settinW"
                                v-if="columns.settings.show"
                            ></th>
                        </tr>
                    </thead>

                    <transition-group name="flip-list" tag="tbody">
                        <tr
                            v-for="customForm in list"
                            :key="customForm.id"
                            class="cursor-pointer"
                        >
                            <td v-if="columns.id.show">
                                {{ customForm.id }}
                            </td>
							<td v-if="columns.user.show">
                                {{ customForm.user.name }}
                            </td>
                            <td v-if="columns.project.show">
                                {{ customForm.project.name }}
                            </td>

                            <!-- <td v-if="columns.header.show">
                                {{ customForm.header }}
                            </td> -->

                            <td v-if="columns.link.show">
                                <el-popover
                                    placement="bottom"
                                    width="210" 
                                    trigger="click"
                                    >
                                    <div class="social-buttons">
                                    <el-button
                                        type="primary"
                                        v-for="social in sources" 
                                        class="copy-link m-0"
                                        @click="copyURL(customForm.uuid, social.id)"
                                        :key="'social-'+social.id"
                                        size="medium"
                                        >
                                        {{ social.name }}
                                    </el-button>
                                    </div>
                                    <el-button slot="reference"  type="primary" icon="el-icon-copy-document" class="copy-link" size="medium">
                                        {{ $t('message.copyURL') }}
                                    </el-button>
                                </el-popover>
                            </td>
                            <td v-if="columns.link.show">
                                
                                <el-button-group>
                                    
                                    <el-button @click="copyScript(customForm.uuid)" size="medium" type="primary" icon="el-icon-link">
                                        <!-- {{ $t('message.script') }} -->
                                    </el-button>
                                    <el-button @click="copyButton(customForm.uuid)" size="medium" type="primary" icon="el-icon-menu">
                                        <!-- {{ $t('message.button') }} -->
                                    </el-button>
                                    <el-tooltip placement="top">
                                        <div slot="content" style="font-size: 14px;">
                                            <div class="mb-2">
                                                Вам следует установить скрипт в контенте и установить 
                                                идентификатор: <span style="color: rgb(22, 144, 243);">«gocrmWidgetButton»</span> для кнопки, 
                                                которую вы хотите использовать для виджета.
                                            </div>
                                            <b>ID: </b> <span style="color: rgb(22, 144, 243);">«gocrmWidgetButton»</span>
                                            <div>
                                                <b>Пример кнопки: </b> 
                                                <span style="color: rgb(22, 144, 243);">
                                                    &ltbutton id='gocrmWidgetButton' type='button'>Оставьте заявку &lt/button>
                                                </span>
                                            </div>
                                            <div>
                                                <b>Пример script: </b> 
                                                <span style="color: rgb(22, 144, 243);">
                                                    &ltscript src="https://www.example.com">&lt/script>
                                                </span>
                                            </div>
                                        </div>
                                        <el-button size="medium" type="primary" icon="el-icon-info"></el-button>
                                    </el-tooltip>
                                    
                                </el-button-group>
                            </td>
							<td v-if="columns.deal.show">
                                {{ customForm.type_form ? $t('message.yes') : $t('message.no') }}
                            </td>
							<td v-if="columns.view.show">
                                <el-button type="primary" size="medium" @click="showResponses(customForm)">{{ $t('message.view') }}</el-button>
                            </td>
                            <td v-if="columns.created_at.show">
                                {{ customForm.created_at }}
                            </td>

                            <td v-if="columns.updated_at.show">
                                {{ customForm.updated_at }}
                            </td>

                            <td
                                v-if="columns.settings.show"
                                class="settings-td"
                            >
                                <crm-settings
                                    :name="$options.name"
                                    :model="customForm"
                                    :actions="actions"
                                    :permissionShow="'custom_form.update'"
                                    :permissionDestroy="'custom_form.delete'"
                                    @edit="edit"
                                    @delete="destroy"
                                ></crm-settings>
                            </td>
                        </tr>
                    </transition-group>
                </table>
                <div class="my___pagination">
                    <crm-pagination
                        @c-change="updatePagination"
                        :class="mode ? 'pagination__day' : 'pagination__night'"
                        :pagination="pagination"
                    ></crm-pagination>
                    <!-- <Pagination /> -->
                </div>
            </div>

            <div class="app-modal app-modal__full modal-color-bg">
                <el-drawer
                    :with-header="false"
                    :visible.sync="drawerCreate"
                    ref="drawerCreate"
                    size="100%"
                    @opened="drawerOpened('drawerCreateChild')"
                    @closed="drawerClosed('drawerCreateChild')"
                >
                    <div>
                        <crm-create
                            ref="drawerCreateChild"
                            drawer="drawerCreate"
                        >
                        </crm-create>
                    </div>
                </el-drawer>

                <el-drawer
                    :with-header="false"
                    :visible.sync="drawerUpdate"
                    size="100%"
                    ref="drawerUpdate"
                    @opened="drawerOpened('drawerUpdateChild')"
                    @closed="drawerClosed('drawerUpdateChild')"
                >
                    <crm-update
                        :selectedItem="selectedItem"
                        ref="drawerUpdateChild"
                        drawer="drawerUpdate"
                    ></crm-update>
                </el-drawer>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters, mapActions } from "vuex";
import list from "@/utils/mixins/list";
import CrmCreate from "./components/crm-create";
import CrmUpdate from "./components/crm-update";

export default {
	name: "customForm",
	mixins: [list],
	components: {
		CrmCreate,
		CrmUpdate,
	},

   data() {
		return {
			appURL: process.env.VUE_APP_BASE_URL_FRONT
		};
	},

	computed: {
		...mapGetters({
			list: "customForm/list",
			columns: "customForm/columns",
			pagination: "customForm/pagination",
			filter: "customForm/filter",
			sort: "customForm/sort",
			mode: "MODE",
			authUser: "auth/user",
			sources: "sources/inventory"

		}),
		encryptedAuthUserID(){
			let encryptedText = this.$CryptoJS.AES.encrypt(this.authUser.id.toString(), "Secret goodone gocrm auth user id").toString();
			return  this.$CryptoJS.enc.Base64.stringify(this.$CryptoJS.enc.Utf8.parse(encryptedText));

		},
		actions: function () {
			return ["edit", "delete"];
		},
    },
	created() {
      this.filterForm = JSON.parse(JSON.stringify(this.filter));
      this.debouncedFetchData = _.debounce(this.fetchData, 500);
		this.sourceInventory();
  },
   methods: {
		...mapActions({
			updateList: "customForm/index",
			setPagination: "customForm/setPagination",
			updateSort: "customForm/updateSort",
			updateFilter: "customForm/updateFilter",
			updateColumn: "customForm/updateColumn",
			updatePagination: "customForm/updatePagination",
			show: "customForm/show",
			empty: "customForm/empty",
			delete: "customForm/destroy",
			refreshData: "customForm/refreshData",
			sourceInventory: "sources/inventory"
		}),
		showResponses(custom_form){
			this.$router.replace({name: 'clientForm', params: {custom_form_id: custom_form.id}});
		},
        async copyScript(myuuid){
            let form_url = `${this.appURL}customForm/${myuuid}/${this.encryptedAuthUserID}`;
            let source = this.appURL + '/js/gocrm_widget.js?form_url='+encodeURIComponent(form_url);
            let form_script = '<script src="'+ source + '"><\/script>'

			navigator.clipboard.writeText(form_script).then(function() {
            }).catch(function(err) {
            });
        },
        async copyButton(){
            let form_script = "<button id='gocrmWidgetButton' type='button'>Оставьте заявку</button>"

			navigator.clipboard.writeText(form_script).then(function() {
                console.log('Text copied to clipboard:', form_script);
            }).catch(function(err) {
                console.error('Unable to copy text to clipboard:', err);
            });
        },
        

		async copyURL(myuuid, source_id) {
			try {
				let url = `${this.appURL}customForm/${myuuid}/${this.encryptedAuthUserID}?source_id=${source_id}`;
				await navigator.clipboard.writeText(url);

				this.$message({
					message: this.$t('message.linkCopied'),
					type: 'success'
				});
			} catch($e) {
				console.error('Link Cannot copy', $e);
			}
		}
   },
   beforeRouteLeave (to, from, next) {
		this.$store.commit('customForm/EMPTY_LIST');
		next()
   },
};
</script>
<style>
.copy-link {
  font-size: 12px;
  padding: 9px 6px;
}
.social-buttons{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
}
</style>

