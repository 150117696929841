import { mapGetters, mapActions } from "vuex";
import _ from 'lodash';
import draggable from "vuedraggable";
import {Input, Button, Select, DatePicker, Radio, Switch, Checkbox, InputNumber, ElUpload } from 'element-ui';
import selectProjects from '../../../components/inventory/select-projects';
import multiSelectFilials from '../../../components/multiSelects/multi-select-filials';
import MultiSelectCategories from '../../../components/multiSelects/multi-select-categories';
import Editor from 'ckeditor5-custom-build/build/ckeditor';

export default {
	components:{multiSelectFilials, MultiSelectCategories, selectProjects, draggable, 'el-input': Input, 'el-button': Button, 'el-select': Select, 
		'el-datepicker': DatePicker, 'el-radio': Radio, 'el-switch': Switch, 'el-checkbox': Checkbox, 'el-input-number': InputNumber, 
		'el-upload': ElUpload 
	},
	computed: {
		...mapGetters({
			product_list: 'products/list',
			rules: "customForm/rules",
			model: "customForm/model",
			dialog_model: "customForm/dialog_model",
			columns: "customForm/columns",
			pagination: "customForm/pagination",
			filials: 'filials/inventory',
			mode: "MODE"
		}),
		getMaskFormat: function(){
			return (prop) => {
				if (prop.hasOwnProperty('maskFormat') && prop.maskFormat == 'tel') {
					return '+\\9\\98999999999';
				}
				else return null;
			} 
		}
	},
	data() {
		return {
			custom_form: {},
			custom_rules: {},
			dialog_form: {},
			dialogFormVisible: false,
			dialogProductVisible: false,
			formLabelWidth: '120px',
			data_form: [],
			options: [{label: '', value: '1'}],
			comp_props: {},
			product_ids: [],
			auxiliary_array: [],
			category_ids: [],
			filial_ids: [],
			edit_product: false,
			form_index: null,
			editor: Editor,
			editorConfig: {}
		};
   },
	watch: {
		category_ids: {
			handler: function(newVal){
				if (newVal.length > 0){
					this.getProducts({category_ids: newVal});
					// this.debouncedFetchData(newVal);
				}
				else {
					this.filial_ids = [];
					this.$store.commit('products/EMPTY_LIST');
				} 
			},
			deep: true
		},
	},
	methods: {
		changeComponent(value){
			console.log('data', value);
		},
		...mapActions({
			updatePagination: "customForm/updatePagination",
			getProducts: 'products/getProducts'
		}),
		submit(close = true) {
			if (this.data_form.length > 0){
				this.form['items'] = JSON.parse(JSON.stringify(this.data_form));

				this.$refs["form"].validate((valid) => {
					if (valid) {
						this.loadingButton = true;
						this.save(this.form)
						.then((res) => {
							this.loadingButton = false;
							this.$alert(res);
							this.parent().listChanged();
							if (close) this.close();
						})
						.catch((err) => {
							this.loadingButton = false;
							this.$alert(err);
						});
					}
				});
			}
			else {
				this.$message({
					showClose: true,
					message: this.$t('message.custom_form_is_not_be_empty'),
					type: 'warning'
				});
			}
		},
		debouncedFetchData: _.debounce(function (category_ids){
			this.getProducts({category_ids: category_ids});
		}, 500
		),
		generateDynamicComponent(obj, deal_form = false, with_product = false, drag_event = false) {
			let d_form = JSON.parse(JSON.stringify(obj));

			let index = (this.data_form.length != 0) ? this.data_form.length : 0;
			let name = 'element_' + index;
			let rule = { required: d_form.required, message: this.$t('message.The_field_must_not_be_empty'), trigger: 'change' };

			if (deal_form) {
				if (drag_event){
					if (d_form.type == 'el-switch') {
						this.$set(this.custom_form, name, false);
					}
					else if(d_form.type == 'el-upload'){
						this.$set(this.custom_form, name, []);
					}
					else {
						this.$set(this.custom_form, name, '');
					}
					
					if(d_form.required) {
						this.custom_rules[`${name}`] = [rule];
						// this.$set(this.custom_rules, name, [rule]);
					};
						
					this.data_form.push({
						index: index,
						name: name,
						original_name: d_form.original_name,
						label: d_form.label,
						required: d_form.required,
						type: d_form.type,
						options: d_form.options,
						deal_form: d_form.deal_form,
						not_visible: d_form.not_visible,
						props: d_form.props
					});
				}
				else {
					this.$set(this.custom_form, d_form.name, '');
					if(d_form.required) this.$set(this.custom_rules, d_form.name, [rule]);
	
					this.data_form.unshift({
						index:  d_form.index,
						name:  d_form.name,
						original_name: d_form.original_name,
						label: d_form.label,
						required: d_form.required,
						type: d_form.type,
						options: d_form.options,
						deal_form: d_form.deal_form,
						not_visible: d_form.not_visible,
						props: d_form.props
					});
				}
			}
			else {
				if(d_form.type){
					let main_props = {...this.comp_props}; 
					if (d_form.type == 'el-switch') {
						this.$set(this.custom_form, name, false);
						main_props['active-text'] = d_form.active_text;
						main_props['inactive-text'] = d_form.inactive_text;
						main_props['active-value'] = d_form.active_text;
						main_props['inactive-value'] = d_form.inactive_text;
					}
					else if((d_form.type == 'el-upload') || (d_form.type == 'el-checkbox')){
						this.$set(this.custom_form, name, []);
					}
					else {
						this.$set(this.custom_form, name, '');
					}
					
					if(d_form.required) this.$set(this.custom_rules, name, [rule]);
					
					this.data_form.push({
						index: index,
						name: name,
						label: d_form.label,
						required: d_form.required,
						type: d_form.type,
						options: _.filter(this.options, function (o) {return (o.label != '')}),
						deal_form: d_form.deal_form,
						not_visible: false,
						props: main_props
					});
				}
			}

			if (with_product) {
				let last_obj = _.last(this.data_form);
				last_obj.with_product = true;
				last_obj.filial_ids = d_form.filial_ids;
				last_obj.category_ids = d_form.category_ids;
				last_obj.product_ids = this.product_ids;
				last_obj.original_name = d_form.original_name;
			}

			setTimeout(() => {
				this.$refs['custom_form'].clearValidate();
			}, 50);

			this.comp_props = {};
			this.options = [{label: '', value: '1'}];
			this.dialogFormVisible = false;
		},
		addNewOption(){
			let num = (this.options.length != 0) ? this.options.length + 1 : 1; 
			this.options.push({label: '', value: `${num}`});	
		},
		removeRow(row, which_table_status){
			if (which_table_status) {
				if (this.data_form.length > 0) 
					this.data_form.splice(this.data_form.indexOf(row), 1);
			}
			else
				if (this.options.length > 0)
					this.options.splice(this.options.indexOf(row), 1);
		},
		clearDialogForm(){
			this.dialog_form = JSON.parse(JSON.stringify(this.dialog_model));
		},
		clearDialogProducts(){
			this.filial_ids = [];
			this.category_ids = [];
			this.auxiliary_array = [];
			this.$store.commit('products/EMPTY_LIST');
		},
		changeType(type){
			if (type == 'el-input'){
				this.comp_props = {class: 'w-100'};
			}
			else if (type == 'el-input-number'){
				this.comp_props = {min: 0};
			}
			else if (type == 'el-select'){
				this.comp_props = {size: 'large', class: 'w-100'};
			}
			else if (type == 'el-date-picker'){
				this.comp_props = {type: 'date', 'value-format': 'yyyy-MM-dd', format: 'yyyy-MM-dd'};
			}
			else if (type == 'el-checkbox'){
				this.comp_props = {border: true, label: this.dialog_form.label, is_multiple_choice: true};
			}
			else if (type == 'el-radio'){
				this.comp_props = {border: true,  is_loop: true, is_multiple_choice: false};
			}
			// else if (type == 'el-date-time-picker'){
			// 	this.comp_props = {type: 'datetime', 'value-format': 'yyyy-MM-dd HH:mm', format: 'yyyy-MM-dd HH:mm'};
			// }
		},
		changeFormType(status){
			this.custom_form = {};
			this.custom_rules = {};
			let all_array = [];

			if (status){
				let array = [
					{
						index: 0,
						name: 'element_' + 0,
						original_name: 'full_name',
						label: this.$t('message.client_name'),
						required: true,
						type: 'el-input',
						options: [],
						deal_form: true,
						not_visible: true,
						props: {}
					},
					{
						index: 1,
						name: 'element_' + 1,
						original_name: 'phone',
						label: this.$t('message.phone_number_y'),
						required: true,
						type: 'el-input',
						options: [],
						deal_form: true,
						not_visible: true,
						props: {maskFormat: 'tel'}
					},
					{
						index: 2,
						name: 'element_' + 2,
						original_name: 'type_id',
						label: this.$t('message.clientType'),
						required: true,
						type: 'el-select',
						options: [{label: this.$t('message.individual'), value: 1}, {label: this.$t('message.legal'), value: 2}],
						deal_form: true,
						not_visible: true,
						props: {}
					}
				];
				
				let array2 = JSON.parse(JSON.stringify(this.data_form));
				
				if (array2.length > 0){
					all_array = array.concat(array2);
					all_array = change_data_form(all_array, status);
				}
				else  all_array = [...array];

				this.data_form = [];
				for (let i = all_array.length - 1; i >= 0; i--) {
					this.generateDynamicComponent(all_array[i], true);
				}
			}
			else {
				let array = _.filter(this.data_form, function (ele){ return !ele.deal_form; });
				this.data_form = [];

				if (array.length > 0) {
					array = change_data_form(array, status);
					for (let i = array.length - 1; i >= 0; i--) {
						this.generateDynamicComponent(array[i], true);
					}
				}
			}
			
			function change_data_form(data_form, status){
				let changed_array = _.forEach(data_form, (elem, key) => {
					if (status) {
						if (key > 2){
							elem['index'] = key;
							elem['name'] = 'element_'+key;
						}
					}
					else {
						elem['index'] = key;
						elem['name'] = 'element_'+key;
					}
				});

				return changed_array;
			}
		},
		async changeDrag(){
			this.custom_form = {};
			this.custom_rules = {};
			let array = JSON.parse(JSON.stringify(this.data_form));
			this.data_form = [];

			await this.updateComponentsAfterDrag(array);
			
			this.data_form = JSON.parse(JSON.stringify(this.data_form));
			this.custom_rules = JSON.parse(JSON.stringify(this.custom_rules));
			this.custom_form = JSON.parse(JSON.stringify(this.custom_form));
			this.$refs['custom_form'].resetFields();
		},
		updateComponentsAfterDrag(array){
			for (let i = 0; i <= array.length - 1; i++) {
				let status = false;
				if (array[i].hasOwnProperty('with_product')) {
					status = true;
				}  
				this.generateDynamicComponent(array[i], true, status, true);
			}
			return 'success';
		},
		changeFilials(filial_ids){
			if (filial_ids.length > this.auxiliary_array.length) {
				this.auxiliary_array = filial_ids;

				let last_id = _.last(filial_ids);
				let filial = _.find(this.filials, {'id': last_id});

				if (!_.isEmpty(filial.categories)) {
					_.forEach(filial.categories, category_id => {
						if (!_.includes(this.category_ids, category_id)) {
							this.category_ids.push(category_id);
						}
					});
				}
			}
			else if (filial_ids.length < this.auxiliary_array.length) {
				let filial_id = _.head(_.difference(this.auxiliary_array, filial_ids));
				this.auxiliary_array.splice(this.auxiliary_array.indexOf(filial_id), 1);

				if (filial_ids.length != 0) {
					let removed_filial = _.find(this.filials, {'id': filial_id});

					if (removed_filial.categories.length > 0) {
						let filtered_filials = [];
						_.forEach(this.filials, filial => {
							if (filial_ids.includes(filial.id)){
								filtered_filials.push(filial);
							}
						});
						
						_.forEach(removed_filial.categories, category_id => {
							let check_status = [];
							for (const elem of filtered_filials) {
								if (elem.categories.includes(category_id)) {
									check_status.push(true); break;
								}
								else {check_status.push(false);} 
							}

							if (!check_status.includes(true)) {
								this.category_ids.splice(this.category_ids.indexOf(category_id), 1);
							}
						});
					}
				}
				else {this.category_ids = []; this.product_ids = [];};
			}
		},
		addProducts(product_ids, index){
			if (product_ids.length > 0) {
				let products = [];
				_.forEach(product_ids, product_id => {
					let product = _.find(this.product_list, {'value': product_id});
					products.push(product);
				});
				this.options = products;
				if (!this.edit_product) {
					this.comp_props = {multiple: true};
					let obj = {
						label: this.$t('message.products'),
						original_name: 'product_ids',
						required: false,
						type: 'el-select',
						deal_form: true,
						filial_ids: this.filial_ids,
						category_ids: this.category_ids,
					};
					this.generateDynamicComponent(obj, false, true);
				}
				else {
					let row = _.find(this.data_form, {'index': index});
					this.$set(row, 'options', this.options);
					this.$set(row, 'product_ids', this.product_ids);
					this.$set(row, 'filial_ids', this.filial_ids);
					this.$set(row, 'category_ids', this.category_ids);
				}
			
				this.dialogProductVisible = false;
			}
			else {
				this.$message({
					message: this.$t('message.product_list_is_empty'),
					type: 'warning'
				});
			}
			
		},
		updateProducts(product){
			this.filial_ids = product.filial_ids;
			this.auxiliary_array = product.filial_ids;
			this.category_ids = product.category_ids;
			if (this.product_ids.length == 0) {
				this.product_ids = product.product_ids;
			}
			this.edit_product = true;
			this.form_index = product.index;
			this.dialogProductVisible = true;	
		},
		addProductsSelect(){
			this.dialogProductVisible = true;
			this.edit_product = false;
		},
		
		afterLeave(){
			this.$store.commit('customForm/EMPTY_MODEL');
			this.data_form = [];
		}
	},
	
}